.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10% 0;
  .title {
    h1{
      color: #111;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    text-align: center;
    }
  }
  .categoryList {
    display: grid;
    grid-template-columns: repeat(1fr);
    div {
      a {
        text-decoration: none;
      }
      .cards {
        margin: 1rem;
        &.categoryCard {
          &.Sauces {
            .imgContainer {
              padding: 0 20px;
            }
          }
          .titleContainer {
            p {
              color: #00ab59;
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 580px) and (max-width: 850px) {
    .title {
      h1{
        font-size: 2rem;
      }
    }
    .categoryList {
      grid-template-columns: repeat(2, 1fr);
      .titleContainer {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media screen and (min-width: 851px) and (max-width: 1024px) {
    .title {
      h1{
        font-size: 2rem;
      }
    }
    .categoryList {
      grid-template-columns: repeat(3, 1fr);
      .titleContainer {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    .title {
      h1{
        font-size: 2rem;
      }
    }
    .categoryList {
      grid-template-columns: repeat(5, 1fr);
      .titleContainer {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}
