.review-form {
  // margin: 2rem auto 0 auto;
  padding: 40px;
  // background-color: #f9f9f9;
  // border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;
    label {
      display: block;
      font-size: 18px;
      color: #555;
      margin-bottom: 10px;
      text-align: left;
    }
    textarea,
    input[type="email"],
    input[type="text"]{
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
    textarea {
      height: 70px;
    }
  }

  button[type="submit"] {
    width: 100%;
    background-color: #212121;
    color: #fff;
    border: none;
    padding: 12px 30px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }

  @media screen and (min-width: 769px) {
    width: 50%;
    margin-right: 25px;
    margin-bottom: 20px;
    text-align: start;
    h2 {
      display: grid;
      align-items: center;
    }
  }
}
