.product-container {
  margin: 5% 0;
  display: grid;
  grid-template-columns: 1fr;
  .filter-container {
    display: flex;
    flex-direction: column;
  }
  .selectedCategory {
    text-align: center;
    h1 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
    }
  }
  .products {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    .cards-container {
      .cards {
        margin: 1rem;
        .imgContainer{
          img{
            height: 170px;
            &:last-child{
              height: 210px;
            }
          }
        }
        .titleContainer {
          color: #212121;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
        }
        .subTitleContainer {
          color:#9f9f9f;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}
@media screen and (min-width: 580px) and (max-width: 768px) {
  .product-container {
    grid-template-columns: 1fr;
    .selectedCategory {
      text-align: center;
      h1 {
        padding-left: 2rem;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
      }
    }
    .products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .product-container {
    grid-template-columns: 1fr 2fr;
    .selectedCategory {
      text-align: start;
      h1 {
        padding-left: 2rem;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
      }
    }
    .products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 1025px) {
  .product-container {
    grid-template-columns: 1fr 2fr;
    .selectedCategory {
      text-align: start;
      h1 {
        padding-left: 2rem;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
      }
    }
    .products {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.modal-body{
  .modal-desc {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: 10px;
    padding: 10px;
    .productImg{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      transition: 0.4s;
      &:hover{
        transform: translateY(-0.75rem);
      scale: 1.1;
      }
    }
    .product-desc {
      padding-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .modal-spec {
    padding: 30px;
    .category,
    .ingredient,
    .application,
    .quantity {
      display: flex;
      align-items: flex-start;
      h4 {
        font-size: 1rem;
        display: inline;
        min-width: 110px;
        margin-top: 1px;
      }
      p {
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        padding-left: 5px;
        margin-top: 1px;
      }
    }
  }
  .similarProducts{
    padding: 5%;
    .slick-slider {
      .slick-prev:before,
      .slick-next:before {
        content: "";
      }
      .slick-list {
        .slick-track {
          .slick-slide {
            display: flex;
            justify-content: center;
            .cards{
              height: 200px;
              width: 150px;
              &.similarProduct{
                .imgContainer{
                  img{
                    height: 110px !important;
                    &:last-child{
                      height: 130px !important;
                    }
                  }
                }
                .titleContainer{
                  font-size: 0.75rem;
                  font-weight: 700;
                  p{
                    margin: 0;
                  }
                }
                .subTitleContainer{
                  font-size: 0.6rem;
                  color:#9f9f9f;
                }
              }
            }
          }
        }
      }
    }
    
  }
  @media screen and (min-width: 580px) and (max-width: 768px) {
    .similarProducts {
      padding: 5% 10%;
    }
  }
}