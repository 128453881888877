.hero-section-carousel {
  margin-top: 90px;
  .slick-slider {
    .slick-arrow {
      display: none;
    }
  }

  .carouselItems {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @for $i from 0 through 3 {
      &.item#{$i} {
        height: 550px;

        @media screen and (min-width: 426px) and (max-width: 768px) {
          height: 300px;
        }

        @media screen and (min-width: 769px) and (max-width: 1024px) {
          height: 400px;
        }

        @media screen and (min-width: 1025px) {
          height: 700px;
        }

        background-image: url("../../../images/CarouselSm#{$i}.webp") !important;

        @media screen and (min-width: 426px) and (max-width: 768px) {
          background-image: url("../../../images/CarouselMd#{$i}.webp") !important;
        }

        @media screen and (min-width: 769px) and (max-width: 1024px) {
          background-image: url("../../../images/CarouselLg#{$i}.webp") !important;
        }

        @media screen and (min-width: 1025px) {
          background-image: url("../../../images/CarouselLg#{$i}.webp") !important;
        }
      }
    }
  }
}
