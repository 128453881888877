.exotic-products {
  background: #f7f7f7;
  padding: 5% 0;
  .exotic-products-section {
    .title {
      h1{
        color: #111;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      width: 100%;
      text-align: left;
      padding-left: 50px;
      }
    }
    .products-list {
      padding: 5% 10%;
      .cards {
        .titleContainer {
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
        }
        .subTitleContainer {
          color: #9f9f9f;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
        }
      }
      .slick-slider {
        .slick-prev:before,
        .slick-next:before {
          content: "";
        }
        .slick-list {
          .slick-track {
            .slick-slide {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
    @media screen and (min-width: 580px) and (max-width: 768px) {
      .products-list {
        padding: 5% 25px;
      }
      .title {
        h1{
          font-size: 2rem;
        }
      }
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      .products-list {
        padding: 5% 2%;
      }
      .title {
        h1{
          font-size: 2rem;
        }
      }
    }

    @media screen and (min-width: 1025px) {
      .products-list {
        padding: 5% 0;
      }
      .title {
        h1{
          padding: 0;
        font-size: 2rem;
        }
      }
    }
  }
}
