.category-filter-container {
  display: flex;
  justify-content: center;
  .category-filter-mobile {
    position: relative;
    display: inline-block;
    margin: 2rem 0;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    .heading {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-items: center;
      .rotate-image {
        transition: transform 0.3s ease;
      }
      .rotate-image.up {
        transform: rotate(180deg);
      }
    }
    .filterList {
      display: grid;
      padding: 0 10%;
      padding-bottom: 0;
      gap: 20px;
      width: 100vw;
      transition: max-height 0.3s ease-in-out;
      max-height: 0;
      overflow: hidden;
      label {
        display: flex;
        align-items: center;
        input[type="radio"] {
          margin-right: 15px;
        }
      }
    }
  }
  .category-filter {
    display: none;
    height: 400px;
    width: 280px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--neutrals-04, #e3e3e3);
    padding: 0 20px;
    h2 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0;
    }
    label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      input[type="radio"] {
        margin-right: 5px;
      }
    }
  }
}
@media (min-width: 580px) and (max-width: 768px) {
  .category-filter-mobile {
    .filterList {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 16% !important;
      gap: 30px !important;
    }
  }
  .category-filter-mobile.expanded {
    .filterList {
      gap: 30px !important;
      padding: 0 16% !important;
    }
  }
}

@media (min-width: 769px) {
  .category-filter-container {
    display: flex;
    justify-content: center;
    .category-filter {
      display: block;
    }
    .category-filter-mobile {
      display: none;
    }
  }
}

.category-filter-mobile.expanded .filterList {
  max-height: 300px;
  padding: 0 10%;
  padding-bottom: 20px !important;
}
