.header {
  position: fixed;
  background-color: #fff;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: auto;
    height: 100%;
    padding: 0 2rem;
    img {
      width: 150px;
      height: 70px;
    }
  }
  .nav-menu {
    display: flex;
    background-color: #fff;
    &.active{
      padding-left: 0;
    }
    a {
      color: #000000;
    }
  }
  .nav-item {
    padding: 1rem;
    font-weight: 500;
    .active {
      padding-bottom: 12px;
      border-bottom: 3px solid var(--primary-color);
    }
    a {
      text-decoration: none;
      &:hover {
        padding-bottom: 12px;
        border-bottom: 3px solid var(--primary-color);
      }
    }
  }
}

.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .header {
    max-width: 100%;
    .navbar {
      max-width: 100%;
      img {
        width: 150px;
      }
    }
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 999;
    text-align: center;
    transition: 0.5s;
    &.active {
      left: 0;
    }
  }
  .nav-item {
    margin: 1rem 0;
  }
}
