.review-section {
  margin: 5% 0 0 0;
  .title {
    h1{
      color: #111;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    text-align: left;
    padding-left: 50px;
    }
  }
  .review-carousel {
    padding: 5% 15%;
    .slick-slider {
      .slick-prev:before,
      .slick-next:before {
        content: "";
      }
      .slick-list {
        .slick-track {
          .slick-slide {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    .review-page {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .review-item {
        flex: 0 0 calc(100% - 10px);
        margin-bottom: 20px;
        padding: 20px;
        background-color: #f5f5f5;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        text-align: left;
      }
      .review-text {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .review-author {
        font-size: 12px;
      }
    }
  }
  @media screen and (min-width: 580px) and (max-width: 768px) {
    .title {
      h1{
        font-size: 2rem;
      }
    }
    .review-carousel {
      padding: 5% 10%;
    }
  }
  @media screen and (min-width: 769px) {
    margin: 3% 0 0 0;
    .title {
      h1{
        font-size: 2rem;
      }
    }
    .review-container{
      display: flex;
      justify-content: center;
      align-items: center;
      .review-carousel {
        padding: 5%;
        width: 50%;
      }
    }
  }
}
