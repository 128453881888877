.cards {
  position: relative;
  padding: 1.25rem 0;
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s;
  box-shadow: 0px 3.57447px 3.57447px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  &.white {
    background: #fff;
  }
  &.grey {
    background: #f7f7f7;
  }
  .imgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img{
      transition: 0.4s;
      height: 170px;
      &:hover {
        cursor: pointer;
        transform: translateY(-0.75rem);
        scale: 1.1;
      }
    }
    img:last-child{
      height: 210px;
    }
  }
  .titleContainer {
    .p {
      font-size: 1.5rem;
    }
  }
  .subTitleContainer {
    .p {
      font-size: 1rem;
      color: var(--neutrals-02, #9f9f9f);
      text-align: start;
    }
  }
}
